import React from 'react'
import { graphql } from "gatsby"
import SEO from '../../components/seo'
import NotFound from '../../components/pages/NotFound'
// Don't delete Layout
import Layout from '../../components/layout'


const lang = 'ca'

const NotFoundPage = ({ data, pageContext }) => {
  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "NOTFOUND"
  })
  const { imageNotFound } = data;
  const urlImageNotFound = imageNotFound.nodes[0].publicURL

  return (
    <>
      <SEO title="404: Not found" />
      <NotFound
        lang={lang}
        urls={resultUrls}
        urlImageNotFound={urlImageNotFound}
      />
    </>
  )
}

export const pageQuery = (graphql`
   query notFoundCaQuery {
     imageNotFound: allFile(filter: {extension: {eq: "svg"}, name: {eq: "404"}}) {
       nodes {
        name
        publicURL
       }
     }
   }
 `)

export default NotFoundPage
